<template>
  <div class="content records flex flex-column gap-4">
    <h1>Documents</h1>

    <div class="flex flex-col gap-4">
      <div class="flex items-center justify-between gap-2 w-100">
        <div class="flex gap-8">
          <date-range
            v-model="filters"
            @input="fetchRecords()"
          />

          <div class="flex flex-column gap-1">
            <div>Search:</div>
            <b-form-input
              v-model="filters.search"
              placeholder="Search"
              size="sm"
              autocomplete="off"
              trim
              type="search"
              debounce="500"
              @change="fetchRecords()"
              class="search-field"
            ></b-form-input>
          </div>

        </div>
        <div class="flex gap-2">
          <b-button
            size="sm"
            variant="primary"
            class="text-nowrap"
            @click="createRecord()"
          >
            Upload
          </b-button>
        </div>
      </div>

      <div class="font-bold">
        Showing {{ records.length | format }} / {{ recordCount | format }} record(s)
      </div>

      <table class="table table-google table-highlight table-records">
        <thead>
          <tr>
            <th>Date</th>
            <th>Note</th>
            <th>Files</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading || records.length === 0">
            <td colspan="8">
              <div class="font-bold">
                <span v-if="isLoading">Loading..</span>
                <span v-else-if="records.length === 0">No records found</span>
              </div>
            </td>
          </tr>

          <tr v-for="record in records" :key="record.id">
            <td class="row-fit">{{ record.create_date | isodate }}</td>
            <td class="row-fit text-nowrap">{{ getFirstLine(record.custom_note) }}</td>
            <td>
              <div class="flex flex-wrap gap-2">
                <template v-for="file in record.files">
                  <div
                    :key="`record_${record.id}_file_${file.id}`"
                    class="flex gap-2 items-center"
                  >
                    <i class="fas fa-paperclip"></i>
                    <b-link
                      :href="file.link"
                      target="_blank"
                      class="custom-link text-nowrap"
                    >
                      {{ file.filename }}
                    </b-link>
                  </div>
                </template>
              </div>
            </td>
            <td class="row-fit">
              <div class="flex gap-2">
               <b-button
                  variant="primary"
                  size="sm"
                  @click="editRecord(record)"
                >
                  Open
                </b-button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <b-pagination
        v-model="filters.page"
        :total-rows="recordCount"
        :per-page="filters.per_page"
        @input="fetchRecords(true)"
        :disabled="isLoading"
      ></b-pagination>

    </div>

    <file-modal
      v-if="recordModal.showModal"
      :data="recordModal"
      @change="onRecordChange"
      @delete="onRecordDelete"
    />
  </div>
</template>

<script>
import { getFirstLine } from '@/helpers';

const DateRange = () => import('@/components/invoices/DateRange.vue');
const FileModal = () => import('@/components/filestore/FileModal.vue');

export default {
  name: 'Files',
  components: {
    DateRange,
    FileModal,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
  },
  data() {
    return {
      loadingCount: 0,
      records: [],
      recordCount: 0,
      filters: {
        date_from: null,
        date_to: null,
        page: 1,
        per_page: 50,
        search: '',
      },
      options: {},
      recordModal: {
        showModal: false,
        record: null,
      },
    };
  },
  methods: {
    fetchRecordData() {
      this.loadingCount++;
      this.$http
        .get('/filestore')
        .query(this.filters)
        .then((res) => {
          this.records = res.body.records;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch records: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchRecordCount() {
      this.loadingCount++;
      this.$http
        .get('/filestore_count')
        .query(this.filters)
        .then((res) => {
          this.recordCount = res.body.count;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch record count: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchRecords(paginate) {
      paginate = paginate === undefined ? false : paginate;
      if (paginate === false) {
        this.fetchRecordCount();
        this.filters.page = 1;
      } else {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 50);
      }
      this.fetchRecordData();
    },
    createRecord() {
      this.loadingCount++;
      this.$http
        .post('/filestore')
        .then((res) => {
          this.records.unshift(res.body.record);
          this.fetchRecordCount();
          this.editRecord(res.body.record);
        })
        .catch((err) => {
          this.$toast.error(`Failed to create record: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    editRecord(record) {
      this.recordModal.record = record;
      this.recordModal.showModal = true;
    },
    onRecordChange(recordId) {
      const recordIndex = this.records.findIndex((record) => record.id === recordId);
      if (recordIndex === -1) {
        return;
      }
      this.loadingCount++;
      this.$http
        .get(`/filestore/${recordId}`)
        .then((res) => {
          this.records[recordIndex] = res.body.record;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch record: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    onRecordDelete(recordId) {
      this.records = this.records.filter((record) => record.id !== recordId);
      this.fetchRecordCount();
    },
    getFirstLine(text) {
      return getFirstLine(text);
    },
  },
};
</script>

<style lang="scss" scoped>
  .records {
    max-width: 1800px;
  }

  .table-records .badge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 20px;
  }

  .search-field {
    width: 250px;
  }
</style>
